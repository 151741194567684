import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import Button from '@interness/web-core/src/components/elements/Button/Button';
import Link from '@interness/web-core/src/components/elements/Link/Link';
export const query = graphql`
  query {
    pdf: directusMediaCollection(name: {eq: "beitritt"}) {
      media {
        file {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
    <Wrapper mdxType="Wrapper">
      <h1>{`Beitrittserklärung`}</h1>
      <h2 {...{
        "id": "tradition-verpflichtet"
      }}>{`Tradition verpflichtet`}</h2>
      <p><strong parentName="p">{`Der Freie Verband Deutscher Heilpraktiker e.V.`}</strong>{` sieht seine Aufgaben und Ziele aus der langen Tradition des Berufsstandes heraus. Ein Optimum an Aus- und Weiterbildung sowie ein bestmöglicher Service für seine Mitglieder sind die Pfeiler des Berufsverbandes. Freiheit in der Berufsausübung, die Öffnung für jeden ernsthaft Bemühten und Kooperation über die Verbandsgrenzen hinaus sind festgeschriebene Gesetze aller Verantwortlichen.`}</p>
      <p>{`Unsere Stärke liegt in der Teamarbeit – in der Gemeinschaft. Wir sind frei und unabhängig, wir bemühen uns um Ihr Vertrauen – `}<strong parentName="p">{`werden Sie Mitglied in der ADT im FVDH.`}</strong></p>
      <div style={{
        textAlign: 'center'
      }}>
        <Button external as={Link} to={props.data.pdf.media[0].file.localFile.publicURL} mdxType="Button">Antrag ADT</Button>
      </div>
      <hr></hr>
      <p>{`Bitte drucken Sie das entsprechende Formular aus und schicken es ausgefüllt zurück an:`}</p>
      <p>{`FVDH – Freier Verband Deutscher Heilpraktiker e.V.
Bundesgeschäftsstelle
Weseler Str. 19-21
48151 Münster`}</p>
    </Wrapper>
    <hr></hr>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      